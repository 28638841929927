@import "src/scss/_base.scss";

:root {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  body {
    color: var(--primary-background);
  }

  .shared-alertify-notifier-style {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    border: none;
  }

  .alertify-notifier .ajs-message.ajs-success {
    @extend .shared-alertify-notifier-style;
    //background-color: rgba(var(--success-rgb), var(--bg-opacity)) !important;
    // color: rgba(var(--white-rgb), var(--text-opacity)) !important;
    text-shadow: none !important
  }

  .alertify-notifier .ajs-message.ajs-warning {
    @extend .shared-alertify-notifier-style;
    background-color: #f9bc0d;
    color: white;
    text-shadow: none !important
  }

  .alertify-notifier .ajs-message.ajs-error {
    @extend .shared-alertify-notifier-style;
    //color: rgba(var(--white-rgb), var(--text-opacity)) !important;
    //background-color: rgba(var(--danger-rgb), var(--bg-opacity)) !important;
    text-shadow: none !important
  }

  .alertify-notifier.ajs-right .ajs-message.ajs-visible {
    border-radius: 8px !important;
  }
}


.custom-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @media (max-width: $medium-viewport) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.save-button {
  border: none;
  color: #ffffff;
  padding: 8px 14px;
  font-weight: 500;
  background-color: var(--disabled-button);

  &.active {
    background-color: var(--primary-background);
    cursor: pointer;
  }

  &:hover:enabled {
    color: var(--button-text);
  }
}

.app-primary-button {
  border: none;
  color: var(--button-text);
  background-color: var(--primary-background);
  padding: 8px 14px;
  font-weight: 500;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
    color: white;
  }

  &:disabled {
    background-color: var(--disabled-button);
  }

  &:disabled:hover {
    opacity: 1;
    color: var(--button-text);
  }
}

.app-bg-primary {
  background-color: var(--primary-background);
}

.cancel-button {
  border: none;
  color: black;
  background-color: var(--grey-1-color);
  padding: 8px 14px;
  font-weight: 500;
}

.show-all-button {
  background-color: rgb(209, 207, 214) !important;
  color: var(--primary-background);
}

.error-feedback {
  width: 100%;
  margin-top: .25rem;
  font-size: .75rem;
  color: #fa6767;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-bottom-radius-0[aria-expanded="true"] {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-none {
  border: 0 !important;
}

.loading-spinner app-loading .loader-container {
  display: flex;
  align-items: center;
  margin: 0 auto;
  height: 40vw;
}

.toast-message-position {
  position: absolute;
  left: 23%;
  width: 91%;

  @media only screen and (max-width: $medium-viewport) {
    left: 9%;
  }

  @media only screen and (min-width: $large-viewport) {
    left: 30%
  }
}

.anchor {
  color: #3191F0;

  &:hover {
    text-decoration: underline;
  }
}

.neutral-anchor {
  display: block;
  padding: .95rem .95rem;
  color: inherit;
}

.table-cell {
  padding: .95rem .95rem !important;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.text-dark-purple {
  color: var(--primary-background);
}

.text-error-light-red-color {
  color: #5218 !important;
}

.search-clear-button {
  width: 12px;
  box-shadow: none;
  background-color: #f1f3fa;
}

.search-background {
  background-color: #f1f3fa
}

.create-user-button {
  color: var(--button-text);
  cursor: pointer;
}

.form-control-custom {
  border: none;
  height: 35px;
  padding-left: 12px;
  padding-right: 22px;
  background-color: #f1f3fa;
  box-shadow: none;
  width: 74%;

  @media (min-width: $medium-viewport) {
    width: 92%;
  }
}

.form-control-custom:focus {
  outline: none;
}

.form-check-input:checked {
  background-color: var(--primary-background) !important;
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: var(--primary-background);
}

.dropdown-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: $small-viewport) {
    grid-template-columns: repeat(1, 1fr);
  }

  .column-placement {
    grid-column: 3;
  }
}

.filter-section {
  display: flex;
  justify-content: center;
}

input:placeholder-shown {
  text-overflow: ellipsis;
  font-style: italic;
}

.wizard-previous {
  background-color: #39AFD1;
  color: white;
  border-color: #39afd1;

  &:hover {
    color: white;
  }
}

.bg-aqua:hover {
  background-color: #0dcaf0;
  opacity: 0.7;
}

.block-background-color {
  background-color: #797878;
  opacity: 0.1;
}

.icon-number {
  border: 2px solid;
  border-radius: 51%;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 10px;
  margin-bottom: 2px;
  font-size: 12px;
}

.icon-color {
  color: rgb(67, 64, 85) !important;
}

.custom-xl-checkbox {
  width: 24px;
  height: 24px;
}

.custom-xl-radio {
  position: absolute;
  width: 24px;
  height: 24px;
}

.align-label-on-custom-checkbox {
  margin-top: 6px;
}

.code-to-uppercase {
  text-transform: uppercase;
}

.modal-body-bold {
  color: #616161;
  font-weight: 600;
}

.d-from-xs-block {
  @media only screen and (min-width: $small-viewport) {
    display: none;
  }
}

.d-xs-none {
  @media only screen and (max-width: $small-viewport) {
    display: none;
  }
}

.d-xxxl-flex {
  @include xl-UHD {
    display: flex !important;
  }
}

.col-xxxl-1 {
  @include xl-UHD {
    width: 14.3%;
  }
}

.col-xxxl-4 {
  @include xl-UHD {
    width: 33.3%;
  }
}

.col-xxxl-6 {
  @include xl-UHD {
    width: 50%;
  }
}

.sm-text-responsive {
  @media only screen and (min-width: 769px) and (max-width: 1120px) {
    font-size: .75rem;
  }
}

.error-background {
  background-image: url("../assets/images/bg-pattern-light.svg");
  background-size: cover;
  background-position: center;
}

.me-md-filter-buttons {
  @media only screen and (min-width: $medium-viewport) {
    margin-right: 20px;
  }
}

.m-refresh-button {
  @media only screen and (min-width: $medium-viewport) {
    margin-top: 39px;
  }
}

.btn-hover {
  width: 133px;
  -webkit-appearance: none;

  &:hover {
    background-color: #f3f2f7;
  }
}

.items-length {
  color: rgb(179, 0, 28);
  @media only screen and (max-width: $small-viewport) {
    font-size: 12px;
  }
}

.dashboard-table-title-fs {
  @media only screen and (max-width: $small-viewport) {
    font-size: 14px;
  }
}

.table, .table-striped > tbody > tr:nth-of-type(odd) {
  color: var(--primary-background);
}

.table > :not(caption) > * > * {
  padding: 0;
}

.scroll-table {
  overflow: auto;
  max-height: 600px;
  white-space: nowrap; /* prevent line breaks in cells */
  -webkit-overflow-scrolling: touch; /* enable momentum scrolling on iOS */
}

.sticky-header-row {
  position: sticky;
  top: 0;
  z-index: 999;
}

.badge-in-progress {
  background-color: var(--grey-1-color);
}

/* DRAGULA CLASSES */
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

/* END DRAGULA */
