:root {
  --cornflower-blue-color: #727cf5;
  --primary-background: rgb(67, 64, 85);
  --disabled-button: rgb(209, 207, 214);
  --button-text: rgb(236, 236, 242);
  --grey-1-color: #dfe2e4;
  --grey-2-color: #838ca6;
  --danger-rgb: 250,92,124;
  --white-rgb: 255,255,255;
  --success-rgb: 10,207,151;
  --warning-rgb: 255,188,0;;
  --font-sans-serif: 'Nunito' ,sans-serif;
  --text-opacity: 1;
  --bg-opacity: 1;
}
@mixin xxs-devices {
  @media only screen and (max-width: 320px) { @content }
}

@mixin xs-devices {
  @media only screen and (min-width: 321px) and (max-width: 375px) { @content }
}

@mixin small-devices {
  @media only screen and (min-width: 376px) and (max-width: 425px) { @content }
}

@mixin tablets {
  @media only screen and (min-width: 426px) and (max-width: 768px) { @content }
}

@mixin small-laptops {
  @media only screen and (min-width: 769px) and (max-width: 1024px) { @content }
}

@mixin large-laptops {
  @media only screen and (min-width: 1025px) and (max-width: 1440px) { @content }
}

@mixin UHD {
  @media only screen and (min-width: 1441px) and (max-width: 2560px) { @content }
}

@mixin xl-UHD {
  @media only screen and (min-width: 2561px) { @content }
}

$xxs-viewport: 321px;
$xs-viewport: 376px;
$small-viewport: 426px;
$medium-viewport: 769px;
$large-viewport: 1024px;
$xl-viewport: 1440px;
$xxl-viewport: 2559px;

